export const timerTimeLeft = 16028; //time in seconds
export const slotsLinks = [
	'https://c-cx.com/anarchyde',
	'https://c-cx.com/anarchyde',
	'https://c-cx.com/anarchyde',
	'https://c-cx.com/anarchyde',
	'https://c-cx.com/anarchyde',
	'https://c-cx.com/anarchyde',
	'https://c-cx.com/anarchyde',
	'https://c-cx.com/anarchyde',
]
export const baseUrl = 'https://c-cx.com/anarchyde';
export const langs = [
	'en',
	'de',
	'enAu',
	'enCa',
	'es',
	'frCa',
	'no',
	'ptBr',
	'sk'
]